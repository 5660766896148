import VendorsCookieConsent from './cookie-consent/cookie-consent';
import VendorsFancybox      from './fancybox/fancybox';
import VendorsFlatpickr from "../../default/vendors/flatpickr/flatpickr";
import VendorsBootstrap from "../../default/vendors/bootstrap/bootstrap";

const vendors = () => {
	/* eslint-disable no-console */
	// if (processEnv === 'development') {
	// 	console.log('Vendors');
	// }
	/* eslint-enable no-console */

	VendorsCookieConsent();
	VendorsFancybox();
	VendorsFlatpickr.init();
	VendorsBootstrap.init();
};

export default vendors;
