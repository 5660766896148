/**
 * Modul Event Stack
 */

import {
	needJquery,
	getTransitionDuration, extend
} from '../../../shared/utils/index';


import SelectorEngine from '../../../shared/dom/selector-engine';
import Data from "../../../shared/dom/data";

import Login from "../../components/custom/login/login";
import Details from '../../../shared/components/details/details';
import Toast from '../../../shared/components/toast/toast';

// -------
// Private
// -------
const $ = needJquery();

/**
 * Konstruktor
 *
 * @param {String|Object} [m] - jQuery-Element oder -selektor.
 * @param {Object} [o={}] - Optionen.
 *
 * @constructor
 * @public
 *
 * @returns {Stack}
 */
function Stack(m, o) {
	const root = this;

	// Wurde Element schon initialisiert?
	if (Data.get(m, 'ifab.events.initialized')) {
		return Data.get(m, 'ifab.events.data-api');
	}

	// Einstellungen kombinieren und speichern.
	this.settings = extend({}, Stack.DEFAULTS, o);

	if (typeof m === 'undefined' || $(m).length <= 0)
	{
		return;
	}

	// Wrapper-Container.
	this.$container = $(m).eq(0);

	// Nachladbare Events?
	this.isEndless  = (typeof this.$container.attr('data-endless') !== 'undefined');

	if (this.isEndless === true)
	{
		this.setupEndlessList();
	}
	else
	{
		this.setupDefaultList();
	}

	// API-Zugriffsmöglichkiten
	const api = {
		reload: this.reload.bind(this)
	};

	// API im Container verfügbar machen
	// Data.set(this.$container, 'ifab.events.data-api', api);
	Data.set(m, 'ifab.events.data-api', api);

	// Initialisierungsstatus setzen.
	Data.set(m,'ifab.events.initialized', true);

	return this;
}

/**
 * Versionsnummer.
 *
 * @constant {String}
 */
Stack.VERSION = '1.0.0';

/**
 * Standardoptionen.
 *
 * @constant {Object}
 */
Stack.DEFAULTS = {
	'onEventSuccess': false,
	'onEventFail'   : false,
	'itemsPerPage'  : 12
};

/**
 * Methoden.
 *
 * @type {Object}
 */
Stack.prototype = {
	'reload': function() {
		var root = this;

		// root.setupEndlessList(true);

		root.$container.trigger('events.load', true);
	},
	'setupEndlessList': function(reload) {
		var root   = this,
			// Schon existierende Einträge beim Erstaufruf.
			$items = $('[data-event-item]', this.$container);

		root.$items = $items;

		// XHR-Url.
		this.requestUrl = this.$container.attr('data-endless-request-url') || '';

		// XHR-URL ist Pflicht!
		if (this.requestUrl === '')
		{
			throw new Error('Event stack ... required request url failed!');
		}

		// Einträge pro Seite.
		this.itemsPerPage = parseInt(this.$container.attr('data-endless-items-per-page'), 10);

		if (!this.itemsPerPage || this.itemsPerPage <= 0)
		{
			this.itemsPerPage = root.settings.itemsPerPage;
		}

		// Aktuelle Seite.
		this.page = parseInt(this.$container.attr('data-page'), 10);

		if (!this.page || this.page < 0)
		{
			this.page = 0;
		}

		// Max. verfügbare Einträge.
		this.maxItems = parseInt(this.$container.attr('data-endless-max-items'), 10);

		if (!this.maxItems)
		{
			this.maxItems = 0;
		}

		// Flag zum Check auf ´erstes Laden´.
		// Skript könnte rein theoretisch mit Seite ´4´ initialisiert werden.
		// Da dieses Flag immer mitgesendet wird, könnte der Server beim
		// Laden alles bis inkl. Seite 4 - beim Erstaufruf - zurückgeben.
		this.firstCall = !reload;

		// Anzahl Einträge.
		this.countItems = reload ? 0 : parseInt($items.length, 10);

		// Container vor dem neue Einträge eingefügt werden.
		this.$endlessPosition = $('[data-endless-position]', this.$container);

		// Trigger-Button für das Laden
		// this.$loadTrigger = $('[data-endless-trigger]', this.$endlessPosition);
		this.$loadTrigger = this.$container.siblings('[data-endless-trigger]');

		// Container-Events
		this.$container
			.data({
				'isLoading': 0,
				'isEnd'    : 0
			})
			.on('events.load', function(ev, reload = 0) {
				ev.preventDefault();

				let $events   = $('.event-stack .card.event');
				let scrolledY = window.scrollY;

				if(reload && $events.length) {
					$events.each(function () {
						$(this).remove();
					});
				}

				// Container- Attribut setzen
				root.$container
					.data('isLoading', 1)
					.attr('data-endless', 'loading');

				// Laden der Daten starten.
				$.get(root.requestUrl, {
					'p' : reload ? 'reload' : root.page,
					'pP': root.itemsPerPage
				})
					.done(function(data) {
						root.parseData(data, reload);

						setTimeout(function() {
							if(reload) {
								window.scrollTo(0,scrolledY);
							} else {
								window.scrollBy(0,500);
							}
						}, 500);
					})
					.fail(function(xhr, status, error) {
						throw new Error(xhr, status, error);
					});
			})
			.on('events.loaded', function(ev, data) {
				ev.preventDefault();

				// Flag ´Erstaufruf´ ausschalten.
				root.firstCall = false;

				// Container- Attribut setzen
				root.$container
					.data('isLoading', 0)
					.attr('data-endless', 'loaded');

				if (data !== undefined)
				{
					// Datenprüfen, zusammenstellen etc..
					root.parseData(data);
				}
			})
			.on('events.end', function(ev) {
				ev.preventDefault();

				// Container- Attribut setzen
				root.$container
					.data({
						'isLoading': 0,
						'isEnd'    : 1
					})
					.attr('data-endless', 'end-reached');
			});

		// Trigger-Events
		this.$loadTrigger.on('click', function(ev) {
			ev.preventDefault();
			ev.stopPropagation();

			if (
				root.$container.data('isLoading') === 0 &&
				root.$container.data('isEnd') !== 1
			) {
				root.$container.trigger('events.load');
			}
		});

		// Gibt es schon Einträge, dann parsen.
		if (this.countItems > 0)
		{
			$items.each(function() {
				root.parseItem($(this));
			});

			this.$container.trigger('events.loaded');

			// Wenn Anzahl existierender Einträge jetzt schon kleiner ist
			// als die Anzahl der Einträge pro Seite, dann kann der Trigger
			// entfernt werden ... gibt keine weiteren Einträge!
			if (this.countItems < this.itemsPerPage)
			{
				this.$container.trigger('events.end');
			}
		}
		else
		{
			this.$container.trigger('events.load');
		}
	},

	'parseData': function(data, reload = 0) {
		let root      = this;

		if (!data)
		{
			this.$container.trigger('events.end');

			return;
		}

		// HTML (Einträge) integrieren
		$(data).insertBefore(this.$endlessPosition);

		// Neue Einträge holen, durchlaufen und parsen.
		$('[data-event-item]:not([data-is-parsed])', this.$container).each(function() {
			var
				$this = $(this),
				json  = $this.find('[data-lse]')[0];

			root.parseItem($this);

			if (json)
			{
				Login.renderElementToLoginStatus(json);
			}
		});

		// Seite hochzählen - bei reload Hochzählen verhindern
		if(!reload) {
			this.page ++;
		}

		// Container triggern (Lade-Ende).
		this.$container.trigger('events.loaded');

		// Eintragszähler aktualisieren.
		this.countItems = parseInt($('[data-event-item]', this.$container).length, 10);

		// Ist Eintragszähler >= max. Anzahl an Einträgen, dann Container
		// triggern (Event-Ende)
		if (this.maxItems > 0 && (this.countItems >= this.maxItems))
		{
			this.$container.trigger('events.end');
		}

		// Main.bindLightbox(this.$container);
		// Fancybox.bind('[data-fancybox-type="iframe"]', {
		// 	groupAttr: false,
		// 	type     : 'iframe'
		// });
	},

	'setupDefaultList': function() {
		var root   = this,
			// Schon existierende Einträge beim Erstaufruf.
			$items = $('[data-event-item]', this.$container);

		// Anzahl Einträge.
		this.countItems = parseInt($items.length, 10);

		// Gibt es schon Einträge, dann parsen.
		if (this.countItems > 0)
		{
			$items.each(function() {
				root.parseItem($(this));
			});
		}
	},

	'parseItem': function($item) {
		var root     = this,
			isParsed = $item.attr('data-is-parsed');

		if (typeof isParsed === 'undefined')
		{
			$item
				.bind({
					'fbm.blockEvent': function(ev) {
						$(this)
							.attr('data-is-blocked','');
					},
					'fbm.unblockEvent': function(ev) {
						$(this).removeAttr('data-is-blocked');
					},
					'fbm.addOrder'   : function(ev, url) {
						var $this         = $(this),
							$eventControl = $('[data-event-control]', $this),
							attr          = $this.attr('data-no-popup');

						$eventControl.addClass('-is-loading');

						root.sendRequest(url, {}, {
							'onSuccess' : function(data) {
								if (data)
								{
									root._stackHandleAdd($this, data);
								}

								$eventControl.removeClass('-is-loading');

								if (typeof root.settings.onEventSuccess === 'function')
								{
									root.settings.onEventSuccess.apply(root, [$this, data]);
								}

								if (typeof attr !== 'undefined' && attr !== false) {
									Toast.render({
										autohide   : true,
										cssClass   : 'success',
										content    : {
											heading: 'Warenkorb aktualisiert',
											icon   : '<i class="far fa-circle-check"></i>',
											msg    : '<span>Die Veranstaltung wurde dem Warenkorb hinzugefügt.</span>'
										}
									});
								} else {
									Toast.render({
										autohide   : false,
										dismissable: false,
										cssClass   : 'success',
										content    : {
											heading: 'Warenkorb aktualisiert',
											icon   : '<i class="far fa-circle-check"></i>',
											msg    : '<span>Die Veranstaltung wurde dem Warenkorb hinzugefügt.</span>',
											buttons: {
												0 : {
													tpl : '<button type="button" class="button -default -s -circle" data-bs-dismiss="toast"><span class="button__label"><i class="far fa-times"></i></span></button>'
												},
												1 : {
													tpl : `<a href="warenkorb.html" type="button" class="button -default -pill -s"><span class="button__prefix"><i class="far fa-shopping-cart"></i></span><span class="button__label">Zum Warenkorb</span></a>`
												}
											}
										}
									});
								}
							},
							'onFail' : function() {
								$eventControl.removeClass('-is-loading');

								if (typeof root.settings.onEventFail === 'function')
								{
									root.settings.onEventFail.apply(this, [$this]);
								}
							}
						});
					},
					'fbm.removeOrder': function(ev, url) {
						var $this = $(this),
							$eventControl = $('[data-event-control]', $this);

						$eventControl.addClass('-is-loading');

						root.sendRequest(url, {}, {
							'onSuccess' : function(data) {
								if (data)
								{
									root._stackHandleRemove($this, data);
								}

								$eventControl.removeClass('-is-loading');

								if (typeof root.settings.onEventSuccess === 'function')
								{
									root.settings.onEventSuccess.apply(root, [$this, data]);
								}

								Toast.render({
									autohide   : true,
									cssClass   : 'success',
									content    : {
										heading: 'Warenkorb aktualisiert',
										icon   : '<i class="far fa-circle-check"></i>',
										msg    : '<span>Die Veranstaltung wurde aus dem Warenkorb entfernt.</span>'
									}
								});
							},
							'onFail' : function() {
								$eventControl.removeClass('-is-loading');

								if (typeof root.settings.onEventFail === 'function')
								{
									root.settings.onEventFail.apply(this, [$this]);
								}
							}
						});
					},
					'fbm.onInsert': function() {
						var $this = $(this);

						window.setTimeout(function() {
							$this.attr('data-is-parsed', '');
						}, 100);
					}
				})
				.on('click', '[data-control|="add"]', function(ev) {
					var $this = $(this);

					ev.preventDefault();

					$this.closest('[data-event-item]').trigger('fbm.addOrder', [$(this).attr('data-url')]);
				})
				.on('click', '[data-control|="remove"]', function(ev) {
					var $this = $(this);

					ev.preventDefault();

					$this.closest('[data-event-item]').trigger('fbm.removeOrder', [$(this).attr('data-url')]);
				})
				.trigger('fbm.onInsert');

			const accordion = SelectorEngine.findOne('[data-c="details"]', $item[0]);
			if(accordion) {
				Details.init(accordion, {});
			}
		}
	},

	/**
	 * XHR-Anfrage starten.
	 *
	 * @param {String} url
	 * @param {Object} data - zu sendende Daten
	 * @param {Object} callbacks - Callbacks
	 */
	'sendRequest': function(url, data, callbacks) {
		var runtimeCb   = $.extend(true, {}, {
				'onSuccess': function() {},
				'onFail'   : function(data) {}
			}, callbacks || {}),
			runtimeData = $.extend(true, {}, data || {});

		$.getJSON(url, data)
			.done(function(data) {
				runtimeCb.onSuccess.apply(this, Array.prototype.slice.call(arguments, 0));
			})
			.fail(function(xhr, status, error) {
				runtimeCb.onFail.apply(this, Array.prototype.slice.call(arguments, 0));
			});
	},

	/**
	 * Blockierende und nicht zu blockierende Events prüfen.
	 *
	 * @param {Object} data
	 */
	'_stackHandleBlocked': function(data) {
		var i;

		if (data.blocked)
		{
			for(i = 0; i < data.blocked.length; i++)
			{
				$('[data-event-item="' + data.blocked[i] + '"]').trigger('fbm.blockEvent');
			}
		}

		if (data.unblocked)
		{
			for(i = 0; i < data.unblocked.length; i++)
			{
				$('[data-event-item="' + data.unblocked[i] + '"]').trigger('fbm.unblockEvent');
			}
		}
	},

	'_stackHandleAdd': function($el, data) {
		var root = this,
			msg  = {
				'text' : 'Unknown error occured.',
				'title': 'Error',
				'type' : 'warning'
			};

		if (typeof data.msg !== 'undefined')
		{
			msg = $.extend(true, {}, msg, data.msg);
		}

		switch (data.result)
		{
			case 'done':
				$el
					.attr('data-is-basket', '');
				break;

			case 'fail':
				// Bestätigungsabfrage anzeigen
				// $.doConfirm({
				// 	// 'callback': function(value) {
				// 	// 	if (value)
				// 	// 	{
				// 	// 		$collection.trigger('setBasic.scadm');
				// 	// 	}
				// 	// },
				// 	'message' : msg.text,
				// 	'noButton': 'Nein',
				// 	'okButton': 'OK',
				// 	'title'   : msg.title,
				// 	'type'    : msg.type + ' -hide-btn-cancel'
				// });
				console.log('Unknown error occured. - _stackHandleAdd');
				break;
		}

		root._stackHandleBlocked(data);

		if(typeof data.countbasket !== 'undefined')
		{
			$('[data-basket-amount]').text(data.countbasket);
		}
	},

	'_stackHandleRemove': function($el, data) {
		var root = this,
			msg  = {
				'text' : 'Unknown error occured.',
				'title': 'Error',
				'type' : 'warning'
			};

		if (typeof data.msg !== 'undefined')
		{
			msg = $.extend(true, {}, msg, data.msg);
		}

		switch (data.result)
		{
			case 'done':
				$el.removeAttr('data-is-basket');
				break;

			case 'fail':
				// Bestätigungsabfrage anzeigen
				// $.doConfirm({
				// 	// 'callback': function(value) {
				// 	// 	if (value)
				// 	// 	{
				// 	// 		$collection.trigger('setBasic.scadm');
				// 	// 	}
				// 	// },
				// 	'message' : msg.text,
				// 	'noButton': 'Nein',
				// 	'okButton': 'OK',
				// 	'title'   : msg.title,
				// 	'type'    : msg.type + ' -hide-btn-cancel'
				// });

				console.log('Unknown error occured. - _stackHandleRemove');
				break;
		}

		root._stackHandleBlocked(data);

		if(typeof data.countbasket !== 'undefined')
		{
			$('[data-basket-amount]').text(data.countbasket);
		}
	},
};

// -------
// Public
// -------

const init = () => {
	const list = SelectorEngine.findOne('[data-module="event-stack"]');
	if(list) {
		const api = new Stack(list, {});

		// console.log('init');
		// return api;
	}
};

// -------
// Export
// -------

export default {
	init,
	Stack
};